
import Vue from "vue";
import { mapGetters } from "vuex";
import MentorcastImageModal from "@/components/Modals/MentorcastImageModal.vue";
import { InformationData, Mentorcast } from "@/types/interfaces";
import moment from "moment";

export default Vue.extend({
  name: "FinishedMentorcastInfo",
  components: {
    MentorcastImageModal
  },
  data() {
    return {
      infoData: [
        {
          name: "start_date",
          label: "Date",
          icon: "calendar",
          value: null
        },
        {
          name: "start_time",
          label: "Time",
          icon: "time",
          value: null
        },
        {
          name: "seat_price",
          label: "Price per seat",
          icon: "price",
          value: null
        },
        {
          name: "audience",
          label: "Audience",
          icon: "3users",
          value: null
        },
        {
          name: "max_seats",
          label: "No. of seats",
          icon: "seat-block",
          value: null
        }
      ] as InformationData[]
    };
  },
  computed: {
    ...mapGetters({
      mentorcast: "mentorcast/getMentorcast"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
      .then(() => {
        for (const key of Object.keys(this.mentorcast)) {
          const index = this.infoData.findIndex(i => i.name === key);
          if (index > -1) {
            if (key === "start_time") {
              this.infoData[index].value = moment(
                this.mentorcast[key],
                "hh:mm:ss"
              ).format("hh:mm A");
              continue;
            }
            this.infoData[index].value = this.mentorcast[
              key as keyof Mentorcast
            ];
          }
        }
      });
  }
});
