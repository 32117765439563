
import Vue from "vue";
import FinishedMentorcastInfo from "@/components/Mentorcast/FinishedMentorcastInfo.vue";
import FinishedMentorcastRating from "@/components/Mentorcast/FinishedMentorcastRating.vue";
import Ratings from "@/components/Ratings/Ratings.vue";

export default Vue.extend({
  name: "FinishedMentorcastPage",
  components: {
    FinishedMentorcastInfo,
    FinishedMentorcastRating,
    Ratings
  },
  data: function() {
    return {
      ratingTabs: [
        {
          name: "Recent",
          type: "recent"
        },
        {
          name: "Best rated",
          type: "best-rated"
        },
        {
          name: "Worst rated",
          type: "worst-rated"
        }
      ]
    };
  }
});
